<template>
    <r-e-dialog title="发票查验" show-footer class="layer-contract-detail" :visible.sync="dialogVisible" width="1000px"
                top="10vh">
        <div class="flex justify-between">
            <div style="width: 10%;" class="flex align-center">{{newDate}}</div>
            <h3 style="margin: 10px 0">国家税务总局全国增值税发票直验平台</h3>
            <div style="width: 10%;"></div>
        </div>
        <div style="border: #bce8f1 2px solid;padding: 10px" class="flex">
            <div>
                查验次数: 第{{info.checkNumber}}次
            </div>
            <div style="width: 250px;text-align: right">
                查验时间: {{newDate+newTime}}
            </div>
        </div>
        <div>
            <div class="invoice-code-left">
                <dl class="dl-horizontal dl-invoice">
                    <!--<dt>
                        <el-image style="width: 70px; height: 70px"
                                  src="https://img.atobo.com/ProductImg/EWM/UWeb/1/6/2/7/1084/16271084/1.gif"/>
                    </dt>-->
                    <dt>机器编号：{{info.deviceCode}}</dt>
                </dl>
            </div>

            <div class="invoice-title">
                <div class="it-h1">增值税发票样式</div>
                <div class="it-line1"></div>
                <div class="it-line2"></div>
                <div class="it-bg"></div>
            </div>

            <div class="invoice-code">
                <dl class="dl-horizontal dl-invoice">
                    <dt>发票代码：{{info.invoiceCode}}</dt>
                    <dt>发票号码：{{info.invoiceNo}}</dt>
                    <dt>开票日期：{{info.invoiceDate}}</dt>
                    <dt>校 验 码：{{info.checkCode}}</dt>
                </dl>
            </div>
        </div>

        <div class="invoice-table-s1">
            <table style="border-collapse: collapse;">
                <tbody>
                <tr>
                    <td style="width: 4%; vertical-align: middle">
                        <p>
                            <span>购</span>
                            <i>买</i>
                            <span>方</span>
                        </p>
                    </td>
                    <td style="width: 55%">
                        <dl class="dl-horizontal dl-invoice">
                            <dt style="width: 100%">名 称：<span style="color: #717171">{{ info.payerName }}</span>
                            </dt>
                            <dt style="width: 100%">纳税人识别号：<span style="color: #717171">{{ info.payerTaxNo }}</span>
                            </dt>
                            <dt style="width: 100%">地 址、电 话:<span
                                    style="color: #717171">{{ info.payerAddressTel }}</span>
                            </dt>
                            <dt style="width: 100%">开户行及账号：<span
                                    style="color: #717171">{{ info.payerBankAccount }}</span>
                            </dt>
                        </dl>
                    </td>
                    <td style="width: 4%; vertical-align: middle">
                        <p>
                            <span>密</span>
                            <i>码</i>
                            <span>区</span>
                        </p>
                    </td>
                    <td>
                        <dl class="dl-horizontal dl-invoice dl-invoice-notice"></dl>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="invoice-table-s2">
            <table style="border-collapse: collapse;">
                <thead>
                <tr>
                    <th style="width: 25%">*简称*货物或应税劳务、服务名称</th>
                    <th style="width: 10%">规格型号</th>
                    <th style="width: 5%">单位</th>
                    <th style="width: 5%">数量</th>
                    <th style="width: 15%">单价</th>
                    <th style="width: 15%">金额</th>
                    <th style="width: 10%">税率</th>
                    <th>税额</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in info.itemInfos" :key="index">
                    <td class="project-name">{{ item.itemName }}</td>
                    <td>{{item.itemSpec}}</td>
                    <td>{{item.itemUtil}}</td>
                    <td>{{ item.itemQuantity }}</td>
                    <td class="project-price">{{ item.itemExTaxPrice }}</td>
                    <td class="project-amount">{{ item.itemExTaxAmount }}</td>
                    <td class="project-tax">{{ item.itemTaxRate }}</td>
                    <td class="project-tax-amount">{{ item.itemTaxAmount }}</td>
                </tr>
                <tr class="empty">
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                </tr>
                <tr class="count">
                    <td>合计</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td class="project-counter">{{info.exTaxAmount}}</td>
                    <td>&nbsp;</td>
                    <td class="project-tax-counter">{{ info.taxAmount }}</td>
                </tr>
                <tr class="number">
                    <td>价税合计（大写）</td>
                    <td colspan="3" style="border-right: none;" class="project-counter-c">
                        <i class="el-icon-circle-close"></i>{{"贰佰伍拾壹元伍角贰分"}}
                    </td>
                    <td style="border-right: none; text-align: right;">（小写）</td>
                    <td colspan="3" style="text-align: left;" class="project-counter-t">¥ {{info.sumAmount}}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="invoice-table-s3">
            <table style="border-collapse: collapse;">
                <tbody>
                <tr>
                    <td style="width: 4%; vertical-align: middle">
                        <p>
                            <span>销</span>
                            <i>售</i>
                            <span>方</span>
                        </p>
                    </td>
                    <td>
                        <dl class="dl-horizontal dl-invoice">
                            <dt style="width: 100%">名 称：<span style="color: #717171">{{ info.payeeName }}</span></dt>
                            <dt style="width: 100%">纳税人识别号：<span style="color: #717171">{{ info.payeeTaxNo }}</span>
                            </dt>
                            <dt style="width: 100%">地 址、电 话：<span
                                    style="color: #717171">{{ info.payeeAddressTel }}</span></dt>
                            <dt style="width: 100%">开户行及账号：<span
                                    style="color: #717171">{{ info.payeeBankAccount }}</span></dt>
                        </dl>
                    </td>
                    <td style="width: 4%; vertical-align: middle;">
                        <p>
                            <span>备</span>
                            <i>&nbsp;</i>
                            <span>注</span>
                        </p>
                    </td>
                    <td width="300">
                        <textarea v-model="info.remark"/>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </r-e-dialog>
</template>

<script>
    import {invoiceInspection} from "@/api/nuonuo-invoice";
    import {timeFormat} from "@custom";
    export default {
        name: "layer-invoice",
        data() {
            return {
                dialogVisible: false,
                userData: {},
                InvoiceData: {},
                rules: {},
                info: {},
                newDate: "",
                newTime: "",
            };
        },
        mounted() {
            // this.invoiceInspection();
        },
        methods: {
            openDialog() {
                this.invoiceInspection();
            },
            invoiceInspection() {
                let that = this;
                /*const data = {optionField: "326327", invoiceNo: "81075496", invoiceDate: "2022-11-22", invoiceCode: "012002100411"}
                invoiceInspection(data).then(res => {
                    const {info} = res;
                    that.info = {...info};
                    that.dialogVisible = true;
                });*/
                that.info = {
                    backupUrl: "",
                    checkCode: "46848829462379326327",
                    checkNumber: "2",
                    deviceCode: "661619973349",
                    exTaxAmount: "222.59",
                    invalidFlag: "0",
                    invoiceCode: "012002100411",
                    invoiceDate: "2022-11-22",
                    invoiceKind: "10",
                    invoiceNo: "81075496",
                    itemInfos: [
                        {
                            itemExTaxAmount: "263.72",
                            itemExTaxPrice: "263.71681416",
                            itemName: "*服装*服装鞋帽",
                            itemNo: "1",
                            itemQuantity: 1.00000000,
                            itemSpec: "",
                            itemTaxAmount: "34.28",
                            itemTaxRate: "13%",
                            itemUtil: "个",
                            spbm: "1040201990000000000"
                        }, {
                            itemExTaxAmount: "-41.13",
                            itemExTaxPrice: "",
                            itemName: "*服装*服装鞋帽",
                            itemNo: "2",
                            itemQuantity: "",
                            itemSpec: "",
                            itemTaxAmount: "-5.35",
                            itemTaxRate: "13%",
                            itemUtil: "",
                            spbm: "1040201990000000000"
                        }
                    ],
                    payeeAddressTel: "天津市武清区京津电子商务产业园宏达道北侧2号 57807000",
                    payeeBankAccount: "中国建设银行股份有限公司天津武清区支行 12001720800052531259",
                    payeeName: "天津瑞佳讯贸易有限公司",
                    payeeTaxNo: "91120222079642398Y",
                    payerAddressTel: "",
                    payerBankAccount: "",
                    payerName: "浙江凝韵科技有限公司",
                    payerTaxNo: "91330106070986178L",
                    remark: "订单号:256063644918",
                    sumAmount: "251.52",
                    taxAmount: "28.93",
                    url: "",
                }
                that.dialogVisible = true;
                that.newDate = timeFormat(new Date());
                that.newTime = timeFormat(new Date()," HH:mm:ss");
            }
        }
    }
</script>

<style lang="scss" scoped>
    /* 电子发票 */
    .invoice-title {
        width: 240px;
        text-align: center;
        margin-left: 300px;
        position: relative;
    }

    .invoice-title .it-h1 {
        font-size: 24px;
        color: #945520;
    }

    .invoice-title .it-line1 {
        border-bottom: 2px solid #945520;
        width: 220px;
        height: 20px;
        margin-left: 10px;
    }

    .invoice-title .it-line2 {
        border-bottom: 2px solid #945520;
        width: 220px;
        height: 7px;
        margin-left: 10px;
    }

    .invoice-title .it-bg {
        width: 130px;
        height: 85px;
        background: url(/@/assets/images/zhang2.png) no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 55px;
    }

    .invoice-table-s2 {
        width: 100%;
    }

    .invoice-table-s2 table {
        border-left: 2px solid #945520;
        width: 100%;
    }

    .invoice-table-s2 table th {
        border-right: 2px solid #945520;
        color: #945520;
        text-align: center;
        border-bottom: 2px solid #ddd;
        font-weight: normal;
        line-height: 26px;
        font-size: 12px;
    }

    .invoice-table-s2 table td {
        border-right: 2px solid #945520;
        color: #000;
        text-align: center;
        /* border-bottom: 1px solid #ddd; */
        font-weight: normal;
        line-height: 26px;
        font-size: 12px;
    }

    .invoice-table-s2 table .empty td {
        height: 60px;
    }

    .invoice-table-s2 table .count td {
        color: #945520;
        border-bottom: 2px solid #945520;
        border-top: 1px solid #ddd;
    }

    .invoice-table-s2 table .number td {
        border-bottom: none;
        color: #945520;
        height: 36px;
    }

    .invoice-table-s1,
    .invoice-table-s3 {
        margin-top: 40px;
        width: 100%;
    }

    .invoice-table-s1 table,
    .invoice-table-s3 table {
        width: 100%;
        border-top: 2px solid #945520;
        border-left: 2px solid #945520;
    }

    .invoice-table-s1 table td,
    .invoice-table-s3 table td {
        border-right: 2px solid #945520;
        border-bottom: 2px solid #945520;
        font-size: 12px;
        vertical-align: top;
    }

    .invoice-table-s1 table p,
    .invoice-table-s3 table p {
        width: 12px;
        margin: 20px auto;
        color: #945520;
    }

    .invoice-table-s1 table i,
    .invoice-table-s3 table i {
        display: inline-block;
        font-style: normal;
    }

    .invoice-table-s3 {
        margin-top: 0;
    }

    .invoice-table-s3 textarea {
        width: 305px;
        height: 130px;
        border: none;
        margin: 0px;
        resize: unset;
        padding: 5px;
        outline: medium;
    }

    .dl-invoice {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .dl-invoice dt {
        color: #945520;
        font-size: 12px;
        font-weight: normal;
        width: 200px;
    }

    .dl-invoice dd input {
        border: none;
        border-bottom: 1px solid #ddd;
        height: 20px;
        font-size: 12px;
        width: 95%;
        outline: medium;
        margin-top: -2px;
    }

    .dl-invoice-notice dt {
        width: 70px;
    }

    .dl-invoice-notice dd {
        margin-left: 70px;
    }

    .invoice-table-s4 {
        width: 100%;
    }

    .invoice-table-s4 table {
        width: 100%;
    }

    .invoice-table-s4 table td {
        width: 25%;
    }

    .invoice-table-s4 .dl-invoice dt {
        width: 70px;
    }

    .invoice-table-s4 .dl-invoice dd {
        margin-left: 65px;
    }

    .invoice-code {
        width: 200px;
        position: absolute;
        right: 70px;
        top: 180px;
    }

    .invoice-code .dl-invoice {
        margin-top: 10px;
        margin-bottom: 0px;
    }

    .invoice-code .dl-invoice dd {
        padding-bottom: 0;
        font-size: 12px;
    }

    .invoice-code-left {
        width: 200px;
        position: absolute;
        left: 70px;
        top: 180px;
    }
</style>